<template>
    <div>
        <div class="content" v-html="content"></div>
        <Footer />
    </div>
</template>

<script>

import Footer from "../../components/footer.vue";
export default {
    name: "QppContent",
    data() {
        return {
            content: '' //首页宫格详情
        };
    },
    mounted() {
        this.getData()
    },
    methods: {
        // 字典接口
        getData() {
            this.$util.post('/sys-param/list', {
                paramKey: this.$route.query.key
            }).then(res => {
                console.log(res);
                this.content = res.data[0].paramValue.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, (match, capture) => {
                    return match.replace("../../../", this.$util.host + '/')
                });
                // this.content = res.data[0].paramValue.replace('../../../', this.$util.host + '/')

            })
        }
    },
    components: { Footer }
};
</script>

<style lang="scss" scoped>
.content {
    // background: #fff;
    width: 1200px;
    min-height: 300px;
    margin: 50px auto;
    text-align: center;
}
</style>